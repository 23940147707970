<template>
    <div class="px-page mt-5">
        <v-tabs fixed-tabs v-model="tab">
            <v-tab>
                Domains
            </v-tab>
            <v-tab>
                Webspace
            </v-tab>
            <v-tab>
                Teamspeak Server
            </v-tab>
            <v-tab>
                Rootserver
            </v-tab>
            <v-tab>
                Minecraft Server
            </v-tab>
            <v-tab>
                Dedicated Server
            </v-tab>
            <v-tab>
                Nextcloud
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <datatable path="/domains" :headers="[
                    {text: 'Domain', value: 'name'},
                    {text: 'Aktionen', value: 'actions'}
                ]">
                    <template v-slot:[`item.name`]="{ item }">{{ item.sld }}.{{ item.tld }}</template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn small color="primary" :to="{ name: 'dashboard-domain', params: { id: item.id } }">Ansehen</v-btn>
                    </template>
                </datatable>
            </v-tab-item>
            <v-tab-item>
                <datatable path="/webspaces" :headers="[
                    {text: 'ID', value: 'id'},
                    {text: 'Aktionen', value: 'actions'}
                ]">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn small color="primary" :to="{ name: 'dashboard-webspace', params: { id: item.id } }">Ansehen</v-btn>
                    </template>
                </datatable>
            </v-tab-item>
            <v-tab-item>
                <datatable path="/teamspeak-servers" :headers="[
                    {text: 'ID', value: 'id'},
                    {text: 'Adresse', value: 'address'},
                    {text: 'Slots', value: 'slots'},
                    {text: 'Aktionen', value: 'actions'}
                ]">
                    <template v-slot:[`item.address`]="{ item }">{{ item.address + ':' + item.port }}</template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn small color="primary" :to="{ name: 'dashboard-teamspeak-server', params: { id: item.id } }">Ansehen</v-btn>
                    </template>
                </datatable>
            </v-tab-item>
            <v-tab-item>
                <datatable path="/rootservers" :headers="[
                    {text: 'Name', value: 'hostname'},
                    {text: 'Kerne', value: 'cores'},
                    {text: 'RAM', value: 'memory'},
                    {text: 'SSD', value: 'disk'},
                    {text: 'Aktionen', value: 'actions'}
                ]">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn small color="primary" :to="{ name: 'dashboard-rootserver', params: { id: item.id } }">Ansehen</v-btn>
                    </template>
                </datatable>
            </v-tab-item>
            <v-tab-item>
                <datatable path="/minecraftservers" :headers="[
                    {text: 'ID', value: 'id'},
                    {text: 'Adresse', value: 'address'},
                    {text: 'RAM', value: 'memory'},
                    {text: 'SSD', value: 'disk'},
                    {text: 'Aktionen', value: 'actions'}
                ]">

                    <template v-slot:[`item.address`]="{ item }">
                        {{ item.address }}:{{ item.port }}
                    </template>
                    <template v-slot:[`item.memory`]="{ item }">
                        {{ item.memory }} GB
                    </template>
                    <template v-slot:[`item.disk`]="{ item }">
                        {{ item.disk }} GB
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn small color="primary" :to="{ name: 'dashboard-minecraftserver', params: { id: item.id } }">Ansehen</v-btn>
                    </template>
                </datatable>
            </v-tab-item>
            <v-tab-item>
                <datatable path="/dedicated-servers" :headers="[
                    {text: 'ID', value: 'id'},
                    {text: 'Aktionen', value: 'actions'}
                ]">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn small color="primary" :to="{ name: 'dashboard-dedicated-server', params: { id: item.id } }">Ansehen</v-btn>
                    </template>
                </datatable>
            </v-tab-item>
            <v-tab-item>
                <datatable path="/nextcloud-servers" :headers="[
                    {text: 'ID', value: 'id'},
                    {text: 'Aktionen', value: 'actions'}
                ]">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn small color="primary" :to="{ name: 'dashboard-nextcloud', params: { id: item.id } }">Ansehen</v-btn>
                    </template>
                </datatable>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>


<script>
export default {
    data() {
        return {
            tab: 0
        }
    }
}
</script>