var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-page mt-5"},[_c('v-tabs',{attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Domains ")]),_c('v-tab',[_vm._v(" Webspace ")]),_c('v-tab',[_vm._v(" Teamspeak Server ")]),_c('v-tab',[_vm._v(" Rootserver ")]),_c('v-tab',[_vm._v(" Minecraft Server ")]),_c('v-tab',[_vm._v(" Dedicated Server ")]),_c('v-tab',[_vm._v(" Nextcloud ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('datatable',{attrs:{"path":"/domains","headers":[
                {text: 'Domain', value: 'name'},
                {text: 'Aktionen', value: 'actions'}
            ]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.sld)+"."+_vm._s(item.tld))]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","to":{ name: 'dashboard-domain', params: { id: item.id } }}},[_vm._v("Ansehen")])]}}],null,true)})],1),_c('v-tab-item',[_c('datatable',{attrs:{"path":"/webspaces","headers":[
                {text: 'ID', value: 'id'},
                {text: 'Aktionen', value: 'actions'}
            ]},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","to":{ name: 'dashboard-webspace', params: { id: item.id } }}},[_vm._v("Ansehen")])]}}],null,true)})],1),_c('v-tab-item',[_c('datatable',{attrs:{"path":"/teamspeak-servers","headers":[
                {text: 'ID', value: 'id'},
                {text: 'Adresse', value: 'address'},
                {text: 'Slots', value: 'slots'},
                {text: 'Aktionen', value: 'actions'}
            ]},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.address + ':' + item.port))]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","to":{ name: 'dashboard-teamspeak-server', params: { id: item.id } }}},[_vm._v("Ansehen")])]}}],null,true)})],1),_c('v-tab-item',[_c('datatable',{attrs:{"path":"/rootservers","headers":[
                {text: 'Name', value: 'hostname'},
                {text: 'Kerne', value: 'cores'},
                {text: 'RAM', value: 'memory'},
                {text: 'SSD', value: 'disk'},
                {text: 'Aktionen', value: 'actions'}
            ]},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","to":{ name: 'dashboard-rootserver', params: { id: item.id } }}},[_vm._v("Ansehen")])]}}],null,true)})],1),_c('v-tab-item',[_c('datatable',{attrs:{"path":"/minecraftservers","headers":[
                {text: 'ID', value: 'id'},
                {text: 'Adresse', value: 'address'},
                {text: 'RAM', value: 'memory'},
                {text: 'SSD', value: 'disk'},
                {text: 'Aktionen', value: 'actions'}
            ]},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.address)+":"+_vm._s(item.port)+" ")]}},{key:"item.memory",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.memory)+" GB ")]}},{key:"item.disk",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.disk)+" GB ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","to":{ name: 'dashboard-minecraftserver', params: { id: item.id } }}},[_vm._v("Ansehen")])]}}],null,true)})],1),_c('v-tab-item',[_c('datatable',{attrs:{"path":"/dedicated-servers","headers":[
                {text: 'ID', value: 'id'},
                {text: 'Aktionen', value: 'actions'}
            ]},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","to":{ name: 'dashboard-dedicated-server', params: { id: item.id } }}},[_vm._v("Ansehen")])]}}],null,true)})],1),_c('v-tab-item',[_c('datatable',{attrs:{"path":"/nextcloud-servers","headers":[
                {text: 'ID', value: 'id'},
                {text: 'Aktionen', value: 'actions'}
            ]},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","to":{ name: 'dashboard-nextcloud', params: { id: item.id } }}},[_vm._v("Ansehen")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }